<template>
  <Table name=offsideError :columns=tableColumns :fetch=fetchRows :syncURL=false :rowClass=rowClass :state=tableState >
    <template #created="{rec}">
      {{common.formatLocalTime(rec.created)}}
    </template>
    <template #finished="{rec}">
      {{common.formatLocalTime(rec.finished)}}
    </template>
    <template #error="{rec}">
      <span class="text-danger">{{rec.error}}</span>
    </template>
  </Table>
</template>

<script>
import darsan from "darsan"

export default {
  name: "ClientOffsideError",
  
  props: {
    state: Object,
  },
  
  data()
  {
    return {
      tableColumns: [
        {
          name: "created",
          title: "Дата создания",
        },
        {
          name: "do_as",
          title: "Работник",
        },
        {
          name: "finished",
          title: "Дата создания",
        },
        {
          name: "method",
          title: "Дата окончания",
        },
        {
          name: "method",
          title: "Метод",
        },
        {
          name: "topic",
          title: "Тема",
        },
        {
          name: "operation",
          title: "Операция",
        },
        {
          name: "params",
          title: "Параметры",
        },
        {
          name: "error",
          title: "Ошибка",
        },
      ],
      
    }
  },
  
  methods: {
    fetchRows()
    {
      return darsan.get("", "client", `/client/${this.state.uid}/offside/error`)
      .then( data => { return { total: 0, data } })
    },
    
    rowClass(rec)
    {
      if (rec.error) return "danger"
    },
  },
  
  computed: {
    tableState()
    {
      return this.state.uid
    },
  },
  
}
</script>