var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Table", {
    attrs: {
      name: "offsideError",
      columns: _vm.tableColumns,
      fetch: _vm.fetchRows,
      syncURL: false,
      rowClass: _vm.rowClass,
      state: _vm.tableState
    },
    scopedSlots: _vm._u([
      {
        key: "created",
        fn: function({ rec }) {
          return [
            _vm._v(
              "\n    " +
                _vm._s(_vm.common.formatLocalTime(rec.created)) +
                "\n  "
            )
          ]
        }
      },
      {
        key: "finished",
        fn: function({ rec }) {
          return [
            _vm._v(
              "\n    " +
                _vm._s(_vm.common.formatLocalTime(rec.finished)) +
                "\n  "
            )
          ]
        }
      },
      {
        key: "error",
        fn: function({ rec }) {
          return [
            _c("span", { staticClass: "text-danger" }, [
              _vm._v(_vm._s(rec.error))
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }